import React from "react"
import Layout from "../components/layout"
import {
  LargeHeader,
  MediumHeader,
  SmallHeader,
  Subheader,
  Paragraph,
  TextSection,
  Spacer,
} from "../components/BasicText/BasicTextElements"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout activePage="privacy">
    <TextSection>
      <LargeHeader>Privacy Policy</LargeHeader>

      <Paragraph>
        We value your privacy. This site collects basic anonymous usage
        statistics so that we can better understand how this site is used and
        make improvements. We may share this data. For instance, we might post
        an article analyzing site usage. This site also collects information
        voluntarily submitted through forms. We will not share any personal
        identifiable information.
      </Paragraph>
      <Paragraph>
        If you have any questions relating to data collection and privacy,
        please contact us.
      </Paragraph>
      <Paragraph textalign={"right"}>Nov 24, 2022</Paragraph>
    </TextSection>
  </Layout>
)

export const Head = () => (
  <SEO
    title="Privacy Policy"
    description="Read the privacy policy for this website."
  />
)

export default PrivacyPage
